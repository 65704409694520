import { Cart, LineItem } from '../../../@types/cart'
import { VinoshipperIframeMessageProductAdd } from "../../../@types/vs-events";
import { debugLog } from "@/base/vs-log"

const GoogleECommerceEvents = [
  'add_to_cart',
  'remove_from_cart',
  'view_cart',
  'begin_checkout',
]

export class VSAnalyticsGoogle {
  constructor () {
    window.dataLayer = window.dataLayer || []
    debugLog('VSAnalytics :: Google Tag Manager / Analytics V 4 :: Available')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendEvent (name: string, data: Record<string, any>) {
    if (window.gtag) {
      debugLog(`VSAnalytics :: GTM / GAV4 :: AS GTAG :: ${name}`, data)
      window.gtag('event', name, data)
    } else {
      if (GoogleECommerceEvents.includes(name)) {
        debugLog(`VSAnalytics :: GTM / GAV4 :: DATALAYER :: ${name}`, data)
        window.dataLayer.push({ecommerce: null})
        window.dataLayer.push({
          event: name,
          ecommerce: data,
        })
      } else {
        window.dataLayer.push({
          event: name,
        })
      }
    }
  }

  sendUserId (userId: string | null, propertiesGoogle: string[] = []) {
    if (
      userId &&
      propertiesGoogle.length > 0 &&
      window.gtag
    ) {
      propertiesGoogle.forEach((prop) => {
        debugLog(`VSAnalytics :: GTM / GAV4 :: user_id :: ${prop} | ${userId}`)
        window.gtag('config', prop, {
          'user_id': userId,
        })
      })
    }
  }

  private itemsToECommerce (items: LineItem[]) {
    let subTotal = 0

    const itemsECommerce = items.map((lineItem) => {
      subTotal = (subTotal + (lineItem.price * lineItem.qty))

      return {
        item_id: `${lineItem.id}`,
        item_name: lineItem.name,
        item_sku: lineItem.sku,
        item_category: lineItem.category,
        discount: lineItem.discount,
        price: lineItem.price,
        quantity: lineItem.qty,
      }
    })

    return {
      currency: 'USD',
      value: subTotal,
      items: itemsECommerce,
    }
  }

  sendCartView (cart: Cart) {
    const resultsAll = this.itemsToECommerce(cart.contents)
    this.sendEvent('view_cart', resultsAll)
  }

  sendBeginCheckout (cart: Cart) {
    const resultsAll = this.itemsToECommerce(cart.contents)
    this.sendEvent('begin_checkout', resultsAll)
  }

  sendEventAddToCart (event: VinoshipperIframeMessageProductAdd) {
    if (event.product) {
      this.sendEvent('add_to_cart', {
        currency: 'USD',
        value: event.product.price,
        items: [
          {
            item_id: event.id,
            item_name: event.product.name,
            item_sku: event.product.sku,
            item_category: event.product.productCategory,
            price: event.product.price,
            quantity: event.qty,
          }
        ]
      })
    } else {
      this.sendEvent('add_to_cart', {
        items: [
          {
            item_id: event.id,
            quantity: event.qty
          }
        ]
      })
    }
  }

  sendEventRemoveFromCart (event: LineItem) {
    this.sendEvent('remove_from_cart', {
      currency: 'USD',
      value: event.lineTotal,
      items: [
        {
          item_id: event.id,
          item_name: event.name,
          item_sku: event.sku,
          item_category: event.category,
          price: event.lineTotal,
          quantity: event.qty,
        }
      ]
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async client_id_Promise (targetId: string): Promise<any> {
    if (typeof window.gtag === 'function') {
      return new Promise((resolve) => {
        window.gtag('get', targetId, 'client_id', resolve)
      })
    } else {
      return Promise.reject()
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async session_id_Promise (targetId: string): Promise<any> {
    if (typeof window.gtag === 'function') {
      return new Promise((resolve) => {
        window.gtag('get', targetId, 'session_id', resolve)
      })
    } else {
      return Promise.reject()
    }
  }

  async linkGenerateParams (targetId: string, uaPropertyOnly: string | undefined = undefined): Promise<Map<string, string>> {
    const params = new Map<string, string>()

    if (!targetId) {
      // Ignore all analytics calls.
      return Promise.resolve(params)
    }

    if (uaPropertyOnly && window.ga !== undefined) {
      const trackers = window.ga.getAll()
      trackers.forEach((tracker) => {
        if (tracker.get('trackingId') === uaPropertyOnly) {
          const linkerParamString = tracker.get('linkerParam')
          const linkerParam = new URLSearchParams(linkerParamString)
          if (linkerParam.has('_ga')) {
            params.set('_ga', linkerParam.get('_ga') ?? '')
          }
          if (linkerParam.has('_gac')) {
            params.set('_gac', linkerParam.get('_gac') ?? '')
          }
        }
      })
    }

    if (uaPropertyOnly !== targetId) {
      return Promise.allSettled([
        this.client_id_Promise(targetId),
        this.session_id_Promise(targetId),
      ])
        .then((resultsAll) => {
          if (resultsAll[0].status === 'fulfilled' && typeof resultsAll[0].value === 'string') {
            params.set('ga4cid', resultsAll[0].value)
          }
          if (resultsAll[1].status === 'fulfilled' && typeof resultsAll[1].value === 'string') {
            params.set('ga4sid', resultsAll[1].value)
          }
          return params
        })
        .catch(() => {
          return params
        })
    } else {
      return Promise.resolve(params)
    }

  }

  uaWarning () {
    const moreInfoUrl = 'https://support.google.com/analytics/answer/11583528'
    console.warn(`Vinoshipper :: WARNING: Google Universal Analytics ceased operations on July 1st, 2023. Please convert to Google Analytics Version 4 immediately.`, moreInfoUrl)
  }

  uaSendEventPageView (pageName: string) {
    if (typeof window.ga === 'function') {
      this.uaSendToAll((tracker) => {
        debugLog(`VSAnalytics :: Google UA (${tracker.get('trackingId')}) :: Page View ${pageName}`)
        tracker.set('title', pageName)
        tracker.send('pageview')
      })
    }
  }

  uaSendToAll (func: (tracker: UniversalAnalytics.Tracker) => void) {
    this.uaAllTrackers().forEach(func)
  }

  uaAllTrackers (): UniversalAnalytics.Tracker[] {
    if (typeof window.ga === 'function') {
      return window.ga.getAll()
    }
    return []
  }

}
